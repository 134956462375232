import React, { useState } from 'react';
import './case.scss';
// import moment from "moment";
import {getDateBasedOnDeviceLocale} from "../libs/utils";

const TextBubble = ({ message, out = false, time }) => {
    const backgroundColor = out ? '#493AEB' : '#E6E9FF';
    const textColor = out ? '#FFFFFF' : '#707070';
    const hourColor = out ? '#E6E9FF' : '#493AEB';
    const hours = getDateBasedOnDeviceLocale(time);
    // const hours = moment(time).format('MMM Do YYYY, h:mm a');
    return (
        <div
            style={{
                flexDirection: out ? 'row-reverse' : 'row',
                display: 'flex',
                paddingTop: 15,
                paddingRight: 12,
            }}>
            <div
                style={{
                    padding: 16,
                    backgroundColor: backgroundColor,
                    maxWidth: '80%',
                    borderRadius: 8,
                    color: textColor,
                    fontSize: 15,
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                <span>{message}</span>
                <span
                    style={{
                        fontSize: '10px',
                        color: hourColor,
                        marginTop: 10,
                    }}>
                    {hours}
                </span>
            </div>
        </div>
    );
};

const InformationBubble = ({ message }) => {
    return (
        <div
            style={{
                flexDirection: 'row',
                display: 'flex',
                justifyContent: 'center',
                paddingTop: 5,
                borderWidth: 1,
                borderStyle: 'solid',
            }}>
            <div
                style={{
                    flex: 1,
                    // padding: 5,
                    maxWidth: '100%',
                    color: '#707070',
                    fontSize: 15,
                    textAlign: 'center',
                    // borderRadius: 5,
                    // borderLeftWidth: 1,
                    // borderLeftStyle: 'solid',
                    // borderRightWidth: 1,
                    // borderRightStyle: 'solid',
                    // borderColor: '#C0C0C0',
                }}>
                {message}
            </div>
        </div>
    );
};

const Attachment = ({ uri, out }) => {
    let backdrop;
    let imageContainer;
    let image;

    const doSomething = () => {
        if (backdrop.classList.contains('zoom')) {
            backdrop.classList.remove('zoom');
            imageContainer.classList.remove('zoom');
            image.classList.remove('glow');
        } else {
            backdrop.classList.add('zoom');
            imageContainer.classList.add('zoom');
            image.classList.add('glow');
        }
    };

    return (
        <div
            style={{
                flexDirection: out ? 'row-reverse' : 'row',
                display: 'flex',
                paddingTop: 15,
            }}>
            <div
                ref={(e) => (backdrop = e)}
                onClick={doSomething}
                className="Case-event-attachment-backdrop"></div>
            <div
                ref={(e) => (imageContainer = e)}
                onClick={doSomething}
                className="Case-event-attachment-container">
                <img
                    ref={(e) => (image = e)}
                    className="Case-event-attachment"
                    alt={'uploaded attachment'}
                    src={uri}></img>
            </div>
        </div>
    );
};

const CaseEventAttachments = ({ data, out }) => {
    if (!data || !Array.isArray(data)) {
        return null;
    }
    return data
        .map((event, idx) => {
            return (
                <Attachment
                    key={`interactionEventAttachment${idx}`}
                    uri={event.href}
                    out={out}
                />
            );
        })
        .reverse();
};

const CaseEvents = ({ caseInfo, data }) => {
    if (!data || !Array.isArray(data)) {
        return null;
    }
    const workingData = JSON.parse(JSON.stringify(data));
    // if no admin, explain to user
    if (!caseInfo.admin) {
        workingData.push({
            message:
                'A support agent will be assigned to your case within 2 hours and will notify you when they begin working on your case.',
            by: 'INFORMATION',
        });
    } else {
        if (caseInfo.state === 1 && workingData[0].by === 'RECIPIENT') {
            workingData.push({
                message:
                    'A support agent is handling your case.',
                by: 'INFORMATION',
            });
        }
    }
    if (caseInfo.state > 1) {
        workingData.push({
            message: '---- End ----',
            by: 'INFORMATION',
        });
    }
    return workingData.map((event, idx) => {
        const out = event.by === 'RECIPIENT';
        const information = event.by === 'INFORMATION';
        if (information) {
            return (
                <InformationBubble
                    key={`interactionEvent${idx}`}
                    message={event.message}
                />
            );
        }
        return (
            <span key={`interactionEvent${idx}`}>
                <TextBubble
                    // key={`interactionEvent${idx}`}
                    out={out}
                    information={information}
                    message={event.message}
                    time={event.moment}
                />
                {event.attachments ? (
                    <CaseEventAttachments data={event.attachments} out={out} />
                ) : null}
            </span>
        );
    });
};

const CaseHelpOptions = ({ onClose, onCase, status, onChatPress }) => {
    const data = [
        {
            title: 'My parcel is damaged',
            type: 'damage',
        },
        {
            title: 'My order has not arrived',
            type: 'delayed',
        },
       /* {
            title: 'The order has missing parcels',
            type: 'lost',
        },
        {
            title: 'Other issues with delivery',
            type: 'other',
        },
        */
        status && ['DELIVERED', 'REVIEWED'].indexOf(status) < 0 ? (
        {
            title: 'Chat with the Flyper',
            action: () => {
                // Close this widget
                if (onClose && onChatPress && status) {
                    onClose();
                    onChatPress();
                }
            },
        } ) : null,
        {
            title: 'I need to chat with support',
            action: () => {
                // Close this widget
                if (onClose) {
                    onClose();
                    // eslint-disable-next-line no-undef
                    LiveChatWidget.call('maximize');
                }
            },
        },
    ];
    if (!data || !Array.isArray(data)) {
        return null;
    }
    return data.map((event, idx) => {
        if (event) {
            // const out = event.from === myId;
            const onOption = () => {
                if (event.action) {
                    event.action();
                } else if (onCase) {
                    onCase(event);
                }
            };

            return (
                <div
                    key={`chatEvent${idx}`}
                    onClick={event.action || onOption}
                    style={{
                        color: '#707070',
                        paddingTop: 20,
                        paddingBottom: 20,
                        paddingRight: 5,
                        paddingLeft: 5,
                        fontSize: 17,
                        borderBottomWidth: 1,
                        borderBottomStyle: 'solid',
                        borderColor: '#C0C0C0',
                        cursor: 'pointer',
                    }}>
                    {event.title}
                </div>
            );
        }
        return null;
    });
};

const CaseBoxTitle = ({ openCaseInfo }) => {
    const getStateText = ({ state }) => {
        switch (state) {
            case 2: // CANCELLED
                return 'Solved';
            case 3: // CLOSED
                return 'Closed';
            case 4: // SOLVED
                return 'Cancelled';
            case 1: // ACTIVE
            default:
                return null;
        }
    };

    let type;
    switch (openCaseInfo.type) {
        case 'damage':
            type = 'Damaged items';
            break;
        case 'lost':
            type = 'Lost items';
            break;
        case 'delayed':
            type = 'Delayed';
            break;
        default:
            type = 'Other issues';
    }
    return (
        <div
            className="Case-box-title"
            style={{
                flexDirection: 'row',
                display: 'inline-flex',
                fontSize: 10,
            }}>
            <div
                style={{
                    flex: 1,
                    flexDirection: 'column',
                }}>
                <div style={{ fontSize: 20 }}>Support</div>
                <div style={{ fontSize: 15 }}>{getStateText(openCaseInfo)}</div>
            </div>
            <div
                style={{
                    flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    textAlign: 'right',
                    fontSize: 13,
                    alignSelf: 'flex-end',
                    marginRight: 20,
                }}>
                <div>Case {openCaseInfo.number}</div>
                <div>{type}</div>
            </div>
        </div>
    );
};

const BoxTitle = ({ title, style, onBack }) => {
    return (
        <div className="Case-box-title" style={{ flexDirection: 'row' }}>
            {onBack ? (
                <span
                    onClick={onBack}
                    className="Icon Icon-back-arrow"
                    style={{
                        cursor: onBack ? 'pointer' : 'auto',
                        height: 23,
                        filter:
                            'invert(42%) sepia(44%) saturate(11%) hue-rotate(13deg) brightness(95%) contrast(96%)',
                    }} />
            ) : null}
            {title}
        </div>
    );
};

const CaseOptionContent = ({ onClose, onCase, status, onChatPress }) => {
    return (
        <>
            <BoxTitle title={'How can we help you?'} onBack={onClose} />
            <div className="Case-box-content">
                <div
                    style={{
                        display: 'flex',
                        maxFlex: 1,
                        height: 0,
                        flexDirection: 'column',
                        backgroundColor: 'red',
                    }}>
                    <CaseHelpOptions onClose={onClose} onCase={onCase} status={status} onChatPress={onChatPress} />
                </div>
            </div>
        </>
    );
};

const CaseInput = ({
    onSend,
    includeData = {},
    sendButton,
    placeholder,
    multiline,
}) => {
    let ref;

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    paddingTop: 10,
                    alignItems: 'center',

                    borderTopStyle: 'solid',
                    borderTopColor: '#C7C7C7',
                    borderTopWidth: 1,

                    marginTop: 10,
                }}>
                <div
                    ref={(e) => (ref = e)}
                    className="Case-input"
                    role="textbox"
                    placeholder={placeholder}
                    contentEditable={true}></div>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    alignItems: 'space-between',
                    marginTop: 10,
                }}>
                <div
                    onClick={() => {
                        if (onSend && ref.innerText !== '') {
                            onSend(
                                Object.assign(includeData, {
                                    message: ref.innerText,
                                }),
                            );
                        }
                        ref.innerText = '';
                    }}
                    style={{
                        color: '#2EB4FF',
                        fontSize: 15,
                        fontWeight: '600',
                        cursor: 'pointer',
                    }}>
                    {sendButton}
                </div>
            </div>
        </>
    );
};

const CaseHistoryContent = ({
    deliveryId,
    openCase,
    onOpenCase,
    onClose,
    openCaseId,
    openCaseInfo,
    openCaseDetail,
}) => {
    if (!openCase && !openCaseId) {
        return null;
    }
    return (
        <>
            {openCaseId && openCaseInfo ? (
                <CaseBoxTitle
                    title={'Support'}
                    openCaseInfo={openCaseInfo}
                    onBack={onClose}
                />
            ) : null}
            {openCase && !openCaseId ? (
                <BoxTitle title={openCase.title} onBack={onClose} />
            ) : null}
            <div className="Case-box-content">
                <div className="Case-box-content-box">
                    <CaseEvents
                        id={'CaseEvents'}
                        caseInfo={openCaseInfo}
                        data={openCaseDetail}
                    />
                </div>
            </div>
            {!openCaseId ? (
                <CaseInput
                    onSend={onOpenCase}
                    includeData={{
                        deliveryId,
                        type: openCase.type,
                    }}
                    sendButton={'Send'}
                    placeholder={'Describe your issue...'}
                />
            ) : !openCaseInfo || (openCaseInfo && openCaseInfo.state === 1) ? (
                <CaseInput
                    onSend={onOpenCase}
                    sendButton={'Send'}
                    placeholder={'Write a message...'}
                />
            ) : null}
        </>
    );
};

const Case = ({
    deliveryId,
    onClose,
    onOpenCase,
    openCaseId,
    openCaseInfo,
    openCaseDetail,
    delivery,
    onChatPress,
}) => {
    const [openCase, setOpenCase] = useState(null);
    return (
        <div className="Case">
            <div className="bg-gradient-blue Case-header">
                <div className="App-header Case-title ">
                    <div onClick={onClose} style={{ paddingLeft: 26 }}>
                        <span
                            className="Icon Icon-back-arrow"
                            style={{ height: 30 }} />
                    </div>
                    <div
                        style={{
                            flex: 1,
                            textAlign: 'center',
                            fontWeight: '700',
                        }}>
                        {openCase
                            ? openCase.title
                            : 'How can we help you?'}
                    </div>
                    <div style={{ width: 50 }}> </div>
                </div>
                <div className="Case-box">
                    <div onClick={onClose} className="Case-box-close-button">
                        <span
                            className={'Icon Icon-close'}
                            style={{
                                height: 'inherit',
                                width: 23,
                                filter:
                                    'invert(52%) sepia(63%) saturate(683%) hue-rotate(168deg) brightness(106%) contrast(101%)',
                            }} />
                    </div>
                    {!openCaseId && !openCase ? (
                        <CaseOptionContent
                            onClose={onClose}
                            onCase={(requestedOption) => {
                                setOpenCase(requestedOption);
                            }}
                            status={delivery.status}
                            onChatPress={onChatPress}
                        />
                    ) : (
                        <CaseHistoryContent
                            deliveryId={deliveryId}
                            openCaseId={openCaseId}
                            openCaseInfo={openCaseInfo}
                            openCaseDetail={openCaseDetail}
                            openCase={openCase}
                            onOpenCase={onOpenCase}
                            onUpdate={setOpenCase}
                            onClose={onClose}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export { Case };
